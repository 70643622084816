import React, {useState, useEffect} from "react"
import { graphql } from 'gatsby'
import styled from '@emotion/styled';

import Body from "../components/editorial/body";
import Container from '../components/layout/container'
// import SoMeShare from '../components/SoMeShare'
import { breakpoints } from "../styling/breakpoints";
import ArrowRight from "./../icons/arrow-right.js";
import SEO from '../components/seo'
import LinkComponent from "../components/editorial/link";

// styles
import "./../styling/libraries.scss"
import { colors } from "../styling/colors";

export default function CampaignTemplate({ path, data}) {

    const sanity = data.sanityCampaign
  
    const ImageBackground = styled.span`
      background: #fff;
      border-radius: 100%;
      height: 275px;
      width: 275px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 55%;
      }
    `
    const ContactSection = styled.section`
      padding-top: 6.5rem;
    `

    const HeaderStyling = styled.div`
      background-image: url(${sanity.mainImage && sanity.mainImage.asset.fixed.src});
      background-position: center;
      background-size: cover;
      min-height: 630px;

      @media and (min-width: 1441px) {
        min-height: 50vh;
      }

      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-flow: column;
      padding-bottom: 4em;
      position: relative;

      &:before {
        content: "";
        background: linear-gradient(0deg, rgb(31 30 64 / 50%) 10%, transparent);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .title, p {
        color: #fff;
      }
    `

    const HeaderStylingInnerWrapper = styled.div`
      max-width: 900px;
      margin: 0 auto;
      padding: 0 2em;
      position: relative;
    `

    const ContactInHeader = styled.span`
      z-index: 100;
      position: relative;
      width: 100%;
      top: -100px;
      right: 0;
      display: block;
      background: #fff;

      a {
        white-space: nowrap;
        visibility: hidden;
        opacity: 0;
      @media only screen and (${breakpoints.desktop}) {
          visibility: visible;
          opacity: 1;
      }
      background: ${colors.lightgreen03};
      border-radius: 1000px;
      font-weight: bold;
      padding: .8rem 1.3rem;
      position: absolute;
      right: 1em;
      color: #333;
      transform: translateY(0);
      transition: .5s;
      top: 1.25em;
      &:hover {
          background: ${colors.lightgreen02};
          box-shadow: 0 0 0 5px ${colors.green};
      }
      &:focus {
          box-shadow: 0 0 0 5px ${colors.green};
      }
    }

    .sticky {
      position: fixed;
      top: 0;
      right: 0;

      a {
          background: ${colors.lightgreen};
          visibility: visible;
          opacity: 1;
      }
    }
    `

    const ButtonLink = styled.p`
    a {
    top: 1.25em;
    right: 1em;
    padding: .8rem 2rem;
    background: #fff;
    color: #333;
    border-radius: 1000px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.5rem;
    transition: .5s;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 5px ${colors.green};
    }
    }
    `

    const CallToActionButtonLink = styled.p`
      height: 2em;
      a {
        background: #78BE7E;
        padding: .8rem 1.3rem;
        border-radius: 1000px;
        font-weight: bold;
        color: ${colors.blue};
        transition: .5s;
        position: relative;
        display: inline-block;
        transform: translateX(0);

        &:hover {
            background: hls(125, 35%, 41%, 1);
            box-shadow: 0 0 0 5px ${colors.green};
        }

        &:focus {
            box-shadow: 0 0 0 5px ${colors.green};
        }

      }
    `

    const DarkBlueBox = styled.div`
      margin: 1em;
      background-color: ${colors.blue};
      border-radius: 1em;
      padding: 2em;
      margin-bottom: 3rem;

    .title, p {
      color: #fff;
    }

    a {
      color: #fff;
      text-decoration: underline;
      &:hover {
          text-decoration: none;
      }
    }
    `
    const GreyBox = styled.div`
      margin: 1em;
      background-color: #747474;
      border-radius: 1em;
      padding: 2em;
      margin-bottom: 3rem;
      
      .title, p, p *, li {
        color: #fff;
      }

      li {
        margin-left: 1em;
      }
      ol, ul {
        margin-bottom: 1em;
      }
    `

    const [isSticky, setSticky] = useState(false);
    const isBrowser = typeof window !== "undefined"
  
    function logit() {
      if (isBrowser) {
        if (Math.round(window.pageYOffset) > document.getElementById("campaign-header").getBoundingClientRect().height + 104) {
            setSticky(true);
        } else {
          setSticky(false);
        }
      }
    }
    
    useEffect(() => {
      if (isBrowser) {
        function watchScroll() {
          window.addEventListener("scroll", logit);
        }
        watchScroll();
        return () => {
          window.removeEventListener("scroll", logit);
        };
      }
    });    
    
  return (
    <Container>

    <SEO 
      title={sanity.title}
      description={sanity.intro}
      pathname={`/${sanity.slug.current}`}
      image={sanity.mainImage && sanity.mainImage.asset.fixed.src}
    />


    <div id="campaign-header">

    {/* Will be part of header */ }
    <ContactInHeader>
        <span
            className={`${isSticky ? "sticky" : ""}`}
            >
        <a 
            href="#contact">Kontakt oss</a>
        </span>
    </ContactInHeader>

    <HeaderStyling>
        <HeaderStylingInnerWrapper>
        <h1 className="title is-size-1">{sanity.title}</h1>
        <p className="title is-size-3 mb-6">{sanity.intro}</p>

        <CallToActionButtonLink>
            <a className={`${isSticky ? "sticky" : ""}`} id="callToAction" href="#contact">Kontakt oss</a>
        </CallToActionButtonLink>

        </HeaderStylingInnerWrapper>
    </HeaderStyling>
    </div>

    <div className="section">
    <div className="container">
        <div className="columns">
        <div className="column is-6 is-offset-3 mb-3 mt-3">

          <Body blocks={sanity._rawBody} />

        </div>
        </div>
    </div>
    </div>

    <div className="container">
      <DarkBlueBox>
          <div className="columns is-vcentered">
          <div className="column is-6">

              <h2 className="title is-size-2">Hvem kan delta?</h2>

              <Body blocks={sanity._rawDeltagere} />

              {sanity.deltagereLenke && sanity.deltagereLenkeTekst
              ? <p>
                  <LinkComponent 
                    arrow
                    white
                    underlined
                    externalURL={sanity.deltagereLenke}
                    linkText={sanity.deltagereLenkeTekst}
                  />
                </p>
            : null}

          </div>
          <div className={`column is-6 ${!sanity.deltagereVideo ? "is-offset-2" : ""}`}>

            {sanity.deltagereVideo
              ? <div className="mb-3 auto-resizable-iframe">
                  <figure className="image is-16by9">
                    <iframe class="has-ratio" title="vimeo-player" src="https://player.vimeo.com/video/760204381?h=a5120f6106" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                  </figure>
                </div>
              : sanity.deltagereBilde 
                ? <img 
                  width="200"
                  src={sanity.deltagereBilde.asset.fixed.src}
                  alt={sanity.deltagereBildeAlt || ""}
                />
            : null }

          </div>
          </div>
      </DarkBlueBox>
    </div>

    <div id="contact">
    <ContactSection>

        <div className="section mb-6" style={{'background': colors.green02}}>
        
        <div className="container">

            <div className="columns">

            <div className="column is-centered">
                <div className="columns is-centered is-vcentered">

                <div className="column is-5">

                    <h2 className="title is-size-2">Hvordan komme i kontakt</h2>

                    <Body blocks={sanity._rawKontakt} />

                    <ButtonLink>
                      <a href={`mailto:${sanity.epost}?subject=${sanity.emnefelt}`}>
                          <span className="mr-4">Send e-post</span>
                          <ArrowRight fill={"black"}/>
                      </a>
                    </ButtonLink>

                </div>

                <div className="column is-3 is-offset-1">
                    <ImageBackground>
                      <img src="https://cdn.sanity.io/images/8njkenaf/production/214f35f9058a123ef1a4aa1b29024c0934a07e16-106x110.svg" alt="" />
                    </ImageBackground>
                </div>

                </div>
            
            </div>

            </div>

        </div>

        </div>
    </ContactSection>

    </div>

    <div className="container">

    <GreyBox>
        <div className="columns">
        <div className="column is-6 mb-6 mt-6">

            {sanity._rawProsjektleder && <h2 className="title is-4">Prosjektleder og forskningssjef</h2>}
            <Body blocks={sanity._rawProsjektleder} />

            {sanity._rawGodkjenning && <h2 className="title is-4">Godkjenning</h2>}
            <Body blocks={sanity._rawGodkjenning} />

        </div>
        <div className="column is-6 mb-6 mt-6">

            {sanity._rawSykehus && <h3 className="title is-4">Deltakende sykehus</h3>}
            <Body blocks={sanity._rawSykehus} />

            {sanity._rawAdresse && <h3 className="title is-4">Fysiske adresse for oppmøte</h3>}
            <Body blocks={sanity._rawAdresse} />

        </div>
        </div>
    </GreyBox>

    </div>

    </Container>
  )
}

export const query = graphql`
  query SanityCampaign($slug: String!) {
    sanityCampaign(slug: {current: {eq: $slug}}) {
      title,
      intro,
      slug {
        current
      },
      _rawBody,
      _rawDeltagere,
      _rawKontakt,
      epost,
      emnefelt,
      mainImage {
        asset {
          fixed(width: 1500) {
            src
          }
        }
      },
      _rawSykehus,
      _rawAdresse,
      _rawGodkjenning,
      _rawProsjektleder,
      deltagereBilde {
        asset {
          fixed {
            src
          }
        }
      }
      deltagereBildeAlt,
      deltagereVideo,
      deltagereLenke,
      deltagereLenkeTekst
    }
  }
`